<template>
    <div id="user" class="hide-scrollbar" :class="$mq">
        <div class="container" v-if="!password">
            <template v-if="['portrait'].includes($mq)">
                <div class="user-config">
                    <div class="user-container">
                        <userBox :user="user"></userBox>
                        <!-- <button class="btn pin" @click="popupPass()">{{ $t('user.change_pin') }}</button> -->
                    </div>
                    <!-- <div class="legal-conditions" @click="showLegalConditions()">{{$t('conditions.description')}}</div> -->
                </div>
            </template>
            <div class="avatar-container">
                <avatarSelector :user_id="user_id" :userAvatar="user.avatar"></avatarSelector>
            </div>
            <template v-if="['portrait'].includes($mq)">
                <div class="bton-container">
                    <button class="btn pin" @click="popupPass()">{{ $t('user.change_pin_portrait') }}</button>
                </div>
            </template>
            <template v-if="['landscape', 'desktop'].includes($mq)">
                <div class="user-config">
                    <div class="user-container">
                        <userBox :user="user"></userBox>
                        <button class="btn pin" v-if="user.password" @click="popupPass()">{{ $t('user.change_pin') }}</button>
                    </div>
                    <!-- <div class="legal-conditions" @click="showLegalConditions()">{{$t('conditions.description')}}</div> -->
                </div>
            </template>
        </div>
        <div class="numpad-pass-container" v-else>
            <transition-group class="numpad-transition" name="slide" tag="div">
                <div class="numpad-current-pass" v-if="steps.currentPass" :key="1">
                    <div class="numpad-password-content">
                        <div class="title-numpad-password">{{ $t('user.numpad_current_pass') }}</div>
                        <div class="error" v-if="errorPass">
                            <div class="text">{{ $t('login.error_pin') }}</div>
                        </div>
                        <numpad @sendValue="setCurrentPass($event)" :pinType="'password'" :givenValue="currentPass" class="numpad-content"></numpad>
                    </div>
                </div>
                <div class="numpad-new-pass" v-else-if="steps.newPass" :key="2">
                    <div class="numpad-password-content">
                        <div class="title-numpad-password">{{ $t('user.numpad_new_pass') }}</div>
                        <div class="error" v-if="errorPass">
                            <div class="text">{{ $t('login.min_number') }}</div>
                        </div>
                        <numpad @sendValue="setNewPass($event)" :pinType="'password'" :givenValue="newPass" class="numpad-content"></numpad>
                    </div>
                </div>
                <div class="numpad-confirm-pass" v-else-if="steps.finalPass" :key="3">
                    <div class="numpad-password-content">
                        <div class="title-numpad-password">{{ $t('user.numpad_confirm_pass') }}</div>
                        <div class="error" v-if="errorPass">
                            <div class="text">{{ $t('login.no_match') }}</div>
                        </div>
                        <numpad @sendValue="setConfirmPass($event)" :pinType="'password'" :givenValue="finalPass" class="numpad-content"></numpad>
                    </div>
                </div>
            </transition-group>
        </div>
    </div>
</template>

<script>
import avatarSelector from '@/components/domain/user/AvatarSelector'
import userBox from '@/components/domain/user/userBox'
import numpad from '@/components/inputs/Numpad'
export default {
    components: {
        avatarSelector,
        userBox,
        numpad
    },
    name: 'user',
    data() {
        return {
            password: false,
            steps: {
                currentPass: true,
                newPass: false,
                finalPass: false
            },
            currentPass: '',
            newPass: '',
            finalPass: '',
            lastStep: false,
            errorPass: false
        }
    },
    computed: {
        user_id() {
            return this.$store.getters['loginUser/getLocalEmployee']
        },
        user() {
            var employee = this.$store.getters['employee/getEmployee'](this.user_id)
            if (employee) {
                if (!employee.avatar.includes('/')) {
                    employee.avatar = '~@/../img/avatar/' + employee.avatar
                } else {
                    return employee
                }
                return employee
            }
        }
    },
    created() {},
    mounted() {
        this.$bar.reset()
        this.$bar.hide()
    },
    methods: {
        popupPass() {
            // self.$router.push({ name: 'AuditDetail', params: { id: self.check.template_id } })
            var self = this

            self.$popup.confirm({
                message: self.$t('user.popup_pass_text'),
                textSave: self.$t('user.popup_confirm_pass'),
                callSave: function () {
                    self.changePass()
                }
            })
        },
        changePass() {
            this.steps.currentPass = true
            this.password = true
            this.$bar.reset()
            var self = this
            this.$bar.addAction('cancel', {
                opened: true,
                customClass: 'password-cancel left',
                callback: function () {
                    self.cancelSteps()
                }
            })
            this.$bar.addAction('continue', {
                opened: true,
                customClass: 'password-continue',
                callback: function () {
                    self.continueSteps()
                }
            })
        },
        setCurrentPass(result) {
            this.currentPass = result
        },
        setNewPass(result) {
            this.newPass = result
        },
        setConfirmPass(result) {
            this.finalPass = result
        },
        continueSteps() {
            if (this.user.password == sha256(this.currentPass)) {
                this.steps.currentPass = false
                this.steps.newPass = true
                var self = this
                this.$bar.addAction('continue', {
                    opened: true,
                    customClass: 'password-continue',
                    callback: function () {
                        self.continueNewPassSteps()
                    }
                })
                this.errorPass = false
            } else {
                this.errorPass = true
            }
        },
        continueNewPassSteps() {
            if (this.newPass.length > 3 && this.newPass.length < 5) {
                this.steps.confirmPass = false
                this.steps.newPass = false
                this.steps.finalPass = true
                this.lastStep = true
                var self = this
                this.$bar.addAction('save', {
                    opened: true,
                    customClass: 'password-continue',
                    callback: function () {
                        self.finalStep()
                    }
                })
                this.$bar.hideAction('continue')
                this.errorPass = false
            } else {
                this.errorPass = true
            }
        },
        finalStep() {
            if (this.newPass == this.finalPass) {
                var params = {
                    id: this.user_id,
                    pin: sha256(this.newPass)
                }
                this.$store.dispatch('employee/updatePin', params)
                this.cancelSteps()
                this.errorPass = false
            } else {
                this.errorPass = true
            }
        },
        cancelSteps() {
            this.password = false
            this.steps.currentPass = true
            this.steps.newPass = false
            this.steps.finalPass = ''
            this.currentPass = ''
            this.newPass = ''
            this.finalPass = ''
            this.lastStep = false
            this.errorPass = false
            this.$bar.reset()
            this.$bar.hide()
        },
        showLegalConditions() {
            let visible = false
            if (visible) {
                this.$store.dispatch('loginUser/viewConditions')
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.slide-leave-active,
.slide-enter-active {
    transition: 0.3s;
}
.slide-enter {
    transform: translate(90%, 0);
    position: absolute;
}
.slide-leave-to {
    position: absolute;
    transform: translate(-90%, 0);
}

#user {
    height: 100%;

    .container {
        @include display-flex();
        max-width: unset;
        width: 100%;
        height: 100%;
        padding: 0;

        .avatar-container {
            width: 340px;
            height: 100%;
            margin-right: 25px;
            max-width: 340px;
        }
        .user-config {
            width: calc(100% - 340px);
            padding: 60px;

            .user-container {
                max-width: 800px;
            }

            .pin {
                margin-top: 18px;
            }
        }

        .legal-conditions {
            @include interaction();
            @include font-size(ml);
            font-family: $text;
            color: $main-s30;
            position: absolute;
            bottom: 30px;
        }
    }
    .numpad-pass-container {
        @include display-flex();
        @include justify-content(center);
        @include flex-direction(column);
        text-align: center;
        width: 100%;
        height: 100%;

        .numpad-password-content {
            text-align: center;
            width: 100%;
            height: 100%;

            .error {
                @include border-radius(3px);
                @include width-fit-content();
                // display: none;
                margin: 9px auto;
                background-color: $accent-t60;
                color: $accent-s30;
                padding: 12px;
            }

            .actions-password {
                @include display-flex();
                @include justify-content(flex-end);
                width: calc(100% - 200px);

                .cancel-pass {
                    @include background($color: $inactive-dark);
                    color: #fff;
                    width: auto;
                    padding: 12px 25px;
                    border-radius: 200px;
                    margin-right: 12px;
                }
                .continue-pass {
                    @include background($color: $progress);
                    color: #fff;
                    width: auto;
                    padding: 12px 25px;
                    border-radius: 200px;

                    &.confirm {
                        @include background($color: $done);
                    }
                }
            }
            .title-numpad-password {
                @include font-size(xl);
                font-family: $text-bold;
                color: $main;
                text-align: center;
                padding-bottom: 20px;
            }
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
#user.hide-scrollbar.portrait {
    .container {
        @include display-flex();
        @include flex-direction(column);
        @include justify-content(space-between);

        width: 100%;
        height: 100%;
        .avatar-container {
            padding-top: 10px;
            width: 100%;
            margin-right: 0px;
            height: calc(65% - 15px);
            min-width: 100%;
        }
        .user-config {
            width: 100%;
            padding: 10px;
            height: 25%;
        }

        .bton-container {
            @include display-flex();
            @include justify-content(flex-end);
            padding-top: 10px;
            height: 10%;

            .pin {
                @include background($image: img('unlock_ffffff.svg'), $position: 10px center, $size: 25px);
                align-self: flex-end;
                background-color: $main-dark;
                width: auto;
                height: 50px;
                right: 10px;
                bottom: 10px;
                margin-top: 10px;
                padding-left: 40px;
                position: relative;
            }
        }
    }
    .numpad-pass-container {
        width: 100%;
        height: calc(100% - 70px);

        .numpad-transition {
            width: 100%;
            height: 100%;
        }

        .numpad-current-pass {
            @include display-flex();
            width: 100%;
            height: 100%;
            overflow: auto;

            .numpad-password-content {
                width: 100%;
                height: calc(100% - 60px);

                .title-numpad-password {
                    @include font-size(sm);
                    font-family: $text-bold;
                    color: $main-dark;
                    padding-top: 30px;
                    padding-bottom: 0px;
                    width: 100%;
                }

                .error {
                    @include border-radius(3px);
                    @include width-fit-content();
                    // display: none;
                    margin: 9px auto;
                    background-color: $accent-t60;
                    color: $accent-s30;
                    padding: 12px;
                    width: 90%;
                }
            }
        }
        .numpad-new-pass {
            @include display-flex();
            width: 100%;
            height: 100%;
            overflow: auto;

            .numpad-password-content {
                width: 100%;
                height: calc(100% - 60px);

                .title-numpad-password {
                    @include font-size(sm);
                    font-family: $text-bold;
                    color: $main-dark;
                    padding-top: 30px;
                    padding-bottom: 0px;
                    width: 100%;
                }
            }
        }
        .numpad-confirm-pass {
            @include display-flex();
            width: 100%;
            height: 100%;
            overflow: auto;

            .numpad-password-content {
                width: 100%;
                height: calc(100% - 60px);

                .title-numpad-password {
                    @include font-size(sm);
                    font-family: $text-bold;
                    color: $main-dark;
                    padding-top: 30px;
                    padding-bottom: 0px;
                    width: 100%;
                }
            }
        }
    }
}
</style>
